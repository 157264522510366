import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  getDyeing,
  getNext,
  createDirectDyeing,
  updateDirectDyeing,
  getSpecificRawMaterials,
  getSpecificDyeing,
  dyeingCancel,
  handleSearch,
  getVefifiedDetail,
} from "./thunk";

const initialState = {
  loading: false,
  loadingUpdated: false,
  loadingDyeing: false,
  loadingNext: false,
  count: null,
  next: null,
  previous: null,
  dyeings: [],
  edit: false,
  editDirect: false,
  dyeing: null,
  loadingSpecific: false,
  loadingCancel: false,
  loadingRawMaterials: false,
  rawMaterials: [],
  customerOrder: null,
  dyeingDetails: [],
};

export const dyeingSlice = createSlice({
  name: "dyeing",
  initialState,
  reducers: {
    getRawMaterialsDetailSuccess: (state, action) => {
      // const master = action.payload;
      // const data1 = Object.freeze(master.dyeingDetails);
      // const sortedById = [].concat(data1).sort((a, b) => b.id - a.id);
      state.rawMaterials = action.payload;
    },
    dyeingEditSuccess: (state, action) => {
      const dyeing = state.dyeings.find((dye) => dye.id === action.payload);

      const updatedRawMaterials = dyeing?.dyeingDetails?.map((detail) => {
        const { item, colorCode, quantity } = detail?.rawMaterials;
        return {
          id: detail?.id,
          item: item,
          expectedQuantity: quantity,
          dyeingCost: detail?.dyeingCost,
          status: detail?.status,
          colorCode: colorCode,
          dyeingQuantity: detail?.dyeingQuantity,
          receivedQuantity: detail.receivedQuantity,
          difference: detail?.difference,
          percentage: detail?.percentage,
          unique: uuidv4(),
        };
      });
      // const data1 = Object.freeze(updatedRawMaterials);

      // const sortedById = [].concat(data1).sort((a, b) => b.id - a.id);
      state.edit = true;
      state.dyeing = dyeing;
      state.rawMaterials = updatedRawMaterials;
    },
    dyeVerificationProcess: (state, action) => {
      const dyeing = state.dyeings.find((dye) => dye.id === action.payload);

      const updatedRawMaterials = dyeing?.dyeingDetails?.map((detail) => {
        const { accept, percentage, ...remainingDetail } = detail;
        const { item, colorCode, quantity } = remainingDetail.rawMaterials;
        return {
          ...remainingDetail,
          percentage: percentage,
          grossDyingCost: 0,
          total: 0,
          penalty: 0,

          accept: Number(percentage) >= -10 ? true : false,
        };
      });

      state.dyeing = dyeing;
      state.rawMaterials = updatedRawMaterials;
    },
    rawMaterialsDeleted: (state, action) => {
      state.rawMaterials = state.rawMaterials.filter((temp) => temp.unique !== action.payload);
    },
    rawMaterialsUpdated: (state, action) => {
      state.rawMaterials = action.payload;
    },
    clearRawMaterials: (state) => {
      state.rawMaterials = [];
      state.edit = false;
      state.dyeingDetails = [];
    },
    clearAllDyeing: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingDyeing = false;
      state.dyeing = null;
      state.rawMaterials = [];
      state.loadingRawMaterials = false;
      state.dyeingDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.dyeings = [...state.dyeings, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
      state.edit = false;
    });
    builder.addCase(createDirectDyeing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDirectDyeing.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDirectDyeing.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateDirectDyeing.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateDirectDyeing.fulfilled, (state) => {
      state.loadingUpdated = false;
    });
    builder.addCase(updateDirectDyeing.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addCase(dyeingCancel.pending, (state) => {
      state.loadingCancel = true;
    });
    builder.addCase(dyeingCancel.fulfilled, (state, action) => {
      state.loadingCancel = false;
    });
    builder.addCase(dyeingCancel.rejected, (state) => {
      state.loadingCancel = false;
      state.edit = false;
    });
    builder.addCase(getSpecificDyeing.pending, (state) => {
      state.loadingSpecific = true;
    });
    builder.addCase(getSpecificDyeing.fulfilled, (state, action) => {
      const updatedRawMaterials = action.payload.dyeingDetails?.map((detail) => ({
        ...detail,
        receivedQuantity: 0,
        unique: uuidv4(),
      }));
      state.loadingSpecific = false;
      state.dyeing = action.payload;
      state.rawMaterials = updatedRawMaterials;
    });
    builder.addCase(getSpecificDyeing.rejected, (state) => {
      state.loadingSpecific = false;
    });
    builder.addCase(getSpecificRawMaterials.pending, (state) => {
      state.loadingRawMaterials = true;
      state.edit= false;
    });
    builder.addCase(getSpecificRawMaterials.fulfilled, (state, action) => {
      const newData = action.payload.sort((a, b) => a.id - b.id);
      state.loadingRawMaterials = false;
      state.rawMaterials = newData;
    });
    builder.addCase(getSpecificRawMaterials.rejected, (state) => {
      state.loadingRawMaterials = false;
    });
    builder.addCase(getVefifiedDetail.pending, (state) => {
      state.loadingRawMaterials = true;
    });
    builder.addCase(getVefifiedDetail.fulfilled, (state, action) => {
      state.loadingRawMaterials = false;
      state.dyeingDetails = action.payload[0] ? action.payload[0].dyeingDetails : [];
    });
    builder.addCase(getVefifiedDetail.rejected, (state) => {
      state.loadingRawMaterials = false;
    });
    builder.addMatcher(isAnyOf(getDyeing.pending, handleSearch.pending), (state) => {
      state.loadingDyeing = true;
      state.edit = false;
    });
    builder.addMatcher(isAnyOf(getDyeing.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingDyeing = false;
      state.edit = false;
      state.dyeings = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getDyeing.rejected, handleSearch.rejected), (state) => {
      state.loadingDyeing = false;
    });
  },
});

export const {
  getRawMaterialsDetailSuccess,
  dyeingEditSuccess,
  dyeVerificationProcess,
  rawMaterialsDeleted,
  rawMaterialsUpdated,
  clearRawMaterials,
  clearAllDyeing,
} = dyeingSlice.actions;

export default dyeingSlice.reducer;
