import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

//obtaining the paginated data
export const getStockSubtraction = ({ postsPerPage, startDate = "", endDate = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/stock/stock-subtraction?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};

//obtaining all fiscal sessions
export const getAllStockSubtraction = () => axiosInstance.get(`api/v1/stock/stock-subtraction?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageStockSubtraction = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/stock/stock-subtraction?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

export const createStockSubtraction = (body) => axiosInstance.post(`api/v1/stock/stock-add-sub?ordering=-id`, body);
//searching function
export const handleSearch = ({ search, postsPerPage, startDate = "", endDate = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/stock/stock-subtraction?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};
