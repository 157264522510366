import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

//obtaining the paginated data
export const getPurchaseReturn = ({ postsPerPage, startDate = "", endDate = "", supplier = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/purchase/purchase-return?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&supplier=${supplier}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};

//obtaining all fiscal sessions
export const getAllPurchaseReturn = () => axiosInstance.get(`api/v1/purchase/purchase-return?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPagePurchaseReturn = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/purchase/purchase-return?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createPurchaseReturn = (body) => axiosInstance.post(`api/v1/purchase/purchase-return`, body);

// purchase details
export const getPurchaseDetails = (purchaseNo) =>
  axiosInstance.get(`api/v1/stock/purchase-stock-details?limit=0&purchase__purchase_no=${purchaseNo}`);

//searching function
export const handleSearch = ({ search, postsPerPage, startDate = "", endDate = "", supplier = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/purchase/purchase-return?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&supplier=${supplier}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};
