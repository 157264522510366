import axiosInstance from "../../../../Utils/axios";
import axios from "axios";
let cancelToken;

//obtaining the paginated data
export const getCustomerOrderReport = ({ postsPerPage, startDate = "", endDate = "", customer = "", status = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/report/customer-order-report?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};

//obtaining all fiscal sessions
export const getAllCustomerOrderReport = () => axiosInstance.get(`api/v1/report/customer-order-report?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageCustomerOrderReport = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/report/customer-order-report?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//searching function
export const handleSearch = ({ search, postsPerPage, startDate = "", endDate = "", customer = "", status = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/report/customer-order-report?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};
