import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

// get paginated data
export const getCustomerOrder = ({ postsPerPage, startDate = "", endDate = "", customer = "", status = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/order/customer-order?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};

//obtaining all data
export const getAllCustomerOrder = () => axiosInstance.get(`api/v1/order/customer-order?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageCustomerOrder = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/order/customer-order?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createCustomerOrder = (body) => axiosInstance.post(`api/v1/order/customer-order`, body);
// updated function
export const updateCustomerOrder = (id, body) => axiosInstance.patch(`api/v1/order/customer-order/${id}`, body);
// get item stock quantity
export const itemStockQuantity = (item, colorCode) =>
  axiosInstance.get(`api/v1/stock/stock-item-quantity?item=${item}&color_code=${encodeURIComponent(colorCode)}`);
// get specific customer order details
export const getSpecificCustomerOrder = (id) => axiosInstance.get(`api/v1/order/customer-order/${id}`);
// get specific design composition
export const getSpecificDesignComposition = (id) => axiosInstance.get(`api/v1/order/design/${id}`);

// cancel customer order
export const customerOrderCancel = (id, body) => axiosInstance.patch(`api/v1/order/cancel-customer-order/${id}`, body);

//searching function
export const handleSearch = ({ search, postsPerPage, startDate = "", endDate = "", customer = "", status = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/order/customer-order?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};

export const checkRedundantDataOrderNo = (e, cancelToken) =>
  axiosInstance.get(`api/v1/order/customer-order?order_no=${e.target.value.trim()}`, {
    cancelToken: cancelToken.token,
  });
