import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

//obtaining the paginated data
export const getPurchaseOrder = ({ postsPerPage, startDate = "", endDate = "", supplier = "", status = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/purchase/purchase-order-master?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&supplier=${supplier}&status=${status}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};

//obtaining all fiscal sessions
export const getAllPurchaseOrder = () => axiosInstance.get(`api/v1/purchase/purchase-order-master?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPagePurchaseOrder = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/purchase/purchase-order-master?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

export const getSpecificPurchaseOrder = (id) => axiosInstance.get(`api/v1/purchase/purchase-order-master/${id}`);
//creating function
export const createPurchaseOrder = (body) => axiosInstance.post(`api/v1/purchase/purchase-order-master`, body);
//updating function
export const updatePurchaseOrder = (id, body) =>
  axiosInstance.patch(`api/v1/purchase/purchase-order-master/${id}`, body);

export const createPurchaseOrderReceive = (body) => axiosInstance.post(`api/v1/purchase/good-receipt-master`, body);

export const goodsReceiptPayment = (body) => axiosInstance.post(`api/v1/purchase/good-receipt-payment`, body);

export const getPurchaseOrderReceivedDetail = (purchaseOrderNo) =>
  axiosInstance.get(`api/v1/purchase/good-receipt-master?purchase_order_master__purchase_order_no=${purchaseOrderNo}`);

export const purchaseOrderCancel = (id, body) =>
  axiosInstance.patch(`api/v1/purchase/purchase-order-cancel/${id}`, body);

//searching function
export const handleSearch = ({ search, postsPerPage, startDate = "", endDate = "", supplier = "", status = "" }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/purchase/purchase-order-master?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&supplier=${supplier}&status=${status}`,
    {
      cancelToken: cancelToken.token,
    }
  );
};
