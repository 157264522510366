import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

//obtaining the paginated data
export const getDesignComposition = (postsPerPage) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(`api/v1/order/design?offset=0&limit=${postsPerPage}&ordering=-id`, {
    cancelToken: cancelToken.token,
  });
};

//obtaining all fiscal sessions
export const getAllDesignComposition = () => axiosInstance.get(`api/v1/order/design?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageDesignComposition = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/order/design?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//creating function
export const createDesignComposition = (body) => axiosInstance.post(`api/v1/order/design`, body);
//updating function
export const updateDesignComposition = (id, body) => axiosInstance.patch(`api/v1/order/design/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(`api/v1/order/design?offset=0&limit=${postsPerPage}&search=${search}`, {
    cancelToken: cancelToken.token,
  });
};

// checking the redundant data
export const checkRedundantDataDesignCompositionName = (e, cancelToken) =>
  axiosInstance.get(`api/v1/order/design?name=${e.target.value.trim()}`, {
    cancelToken: cancelToken.token,
  });

export const getDesignCompositionById = (id) => axiosInstance.get(`api/v1/order/design/${id}`);
