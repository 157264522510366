import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

//obtaining the paginated data
export const getSupplier = (postsPerPage) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(`api/v1/party/supplier?offset=0&limit=${postsPerPage}&ordering=-id`, {
    cancelToken: cancelToken.token,
  });
};

//obtaining all fiscal sessions
export const getAllSupplier = () => axiosInstance.get(`api/v1/party/supplier?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageSupplier = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/party/supplier?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//creating function
export const createSupplier = (body) => axiosInstance.post(`api/v1/party/supplier`, body);
//updating function
export const updateSupplier = (id, body) => axiosInstance.patch(`api/v1/party/supplier/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(`api/v1/party/supplier?offset=0&limit=${postsPerPage}&search=${search}`, {
    cancelToken: cancelToken.token,
  });
};
