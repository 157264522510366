import axiosInstance from "../../../Utils/axios";
import axios from "axios";
let cancelToken;

// get paginated data
export const getWeaving = ({
  postsPerPage,
  startDate = "",
  endDate = "",
  customer = "",
  supplier = "",
  status = "",
  isPaid = "",
}) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/production/weave?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}&supplier=${supplier}&is_paid=${isPaid}`,
    { cancelToken: cancelToken.token }
  );
};
//obtaining all data
export const getAllWeaving = () => axiosInstance.get(`api/v1/production/weave?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageWeaving = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/production/weave?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

// updated function
export const createWeaving = (body) => axiosInstance.post(`api/v1/production/weave`, body);

// updated function
export const updateWeaving = (id, body) => axiosInstance.patch(`api/v1/production/weave/${id}`, body);
// get weaving details
export const getWeavingDetails = (id) =>
  axiosInstance.get(`api/v1/order/weaving-ready-raw-materials?limit=0&customer_order=${id}`);

// get specific customer order details
export const getSpecificWeaving = (id) => axiosInstance.get(`api/v1/production/weave/${id}`);

export const saveAsDraft = (id, body) => axiosInstance.patch(`api/v1/production/weave/${id}`, body);

// issue extra yarn
export const issueExtraYarn = (id, body) => axiosInstance.patch(`api/v1/production/extra-yarn-weave/${id}`, body);

export const saveWeaveDetails = (body) => axiosInstance.post(`api/v1/production/save-weave-details`, body);

// cancel customer order
export const weavingCancel = (id, body) => axiosInstance.patch(`api/v1/production/cancel-weave/${id}`, body);

//searching function
export const handleSearch = ({
  search,
  postsPerPage,
  startDate = "",
  endDate = "",
  customer = "",
  supplier = "",
  status = "",
  isPaid = "",
}) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  return axiosInstance.get(
    `api/v1/production/weave?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}&supplier=${supplier}&is_paid=${isPaid}`,
    { cancelToken: cancelToken.token }
  );
};

export const makePayment = (body) => axiosInstance.post(`/api/v1/production/weave-recieved-payment`, body);

// create weaving receive
export const createWeaveReceive = (body) => axiosInstance.post(`api/v1/production/weave-receive`, body);

export const getSepecificWeavingReceive = (id) =>
  axiosInstance.get(`api/v1/production/weave-receive?weave_master=${id}`);

export const updateWeaveReceive = (id, body) => axiosInstance.patch(`api/v1/production/weave-receive/${id}`, body);
